/* eslint-disable max-len */
import $ from 'jquery';

export default {
  mobileWdWidth: 768,
  init() {
    window.util = this;

    if (window.MutationObserver && window.requestAnimationFrame) {
      const observer = new MutationObserver(this.observeAnimate.bind(this));
      observer.observe(document.querySelector('.modal'), {
        attributes: true,
        attributeFilter: ['class', 'data-type'],
      });
    }

    $(window).on('load', () => {
      this.setPcBorrowedBoxHeight();

      this.setBasicMobileLayout();
      $(window).on('resize', this.setBasicMobileLayout.bind(this));

      $('.modal').on('click', this.closeModal.bind(this));

      $('[disabled]').on('click', (e) => {
        e.preventDefault();
      });

      $('.nav-operating .open-search-btn').on('click', this.toggleSearchBar.bind(this));
      $('.nav-operating .search-cancel').on('click', this.toggleSearchBar.bind(this));

      $('.borrow-book-btn').on('click', this.toggleBorrowedBox.bind(this));
      $('body').on('click', this.closeBorrowedBoxIfOpened.bind(this));

      $('.page-top-btn').on('click', this.scrollToTop.bind(this));

      $('.btn-switch-type').on('click', this.switchInstType.bind(this));
      $('.step-item').on('click', this.switchInstStep.bind(this));
      $('.inst-intro-next').on('click', this.goNextInstStep.bind(this));
    });
  },
  initUnMobileLayout() {
    $('.main-area').css('height', 'auto');
    $('.main-area >*:last-child').css('padding-bottom', '0px');
    this.setPcBorrowedBoxHeight();
  },
  setPcBorrowedBoxHeight() {
    if ($('.borrowed-box').length < 1 || $(window).innerWidth() <= this.mobileWdWidth) {
      return;
    }
    $('.borrowed-box').css('height', 'auto');
    $('.borrowed-box').css('top', '50px');
    const boxTop = parseInt($('.borrowed-box').css('top'), 10);
    const boxPd = $('.borrowed-box').outerWidth() - $('.borrowed-box').width();
    const explainH = $('.borrowed-words').outerHeight();
    const pageTopBtnTop = document.querySelector('.page-top-btn').getClientRects()[0].top;
    const listMaxHeight = pageTopBtnTop - boxTop - boxPd * 2 - explainH;
    $('.borrowed-list').css('max-height', `${listMaxHeight}px`);
  },
  setBasicMobileLayout() {
    //     if ($('.nav').length < 1 || $('.nav-list').length < 1 || $('.main-area').length < 1) {
    //       return;
    //     }
    if ($(window).innerWidth() > this.mobileWdWidth) {
      // this.initUnMobileLayout();
      this.setPcBorrowedBoxHeight();
      return;
    }
    //     if ($(window).innerWidth() > $(window).innerHeight()) {
    //       $('.main-area >*:last-child').css('padding-bottom', '100px');
    //       return;
    //     }
    //
    const {
 windowH, navH, btmListH, pageTopBtnH,
} = {
      windowH: $(window).innerHeight(),
      navH: $('.nav').outerHeight(),
      btmListH: $('.nav-list').outerHeight(),
      pageTopBtnH: $('.page-top-btn').outerHeight(),
    };
    //     const pageTopBtnBtm = parseInt($('.page-top-btn').css('bottom'), 10) - btmListH;
    //
    //     $('.main-area').css('height', `${windowH - navH - btmListH}px`);
    //     $('.main-area >*:last-child').css('padding-bottom', `${pageTopBtnH + pageTopBtnBtm + 10}px`);
    //
    //     if ($('.borrowed-box').length < 1) {
    //       return;
    //     }

    this.setBorrowedBoxMobileLayout({ windowH, btmListH });
  },
  toggleSearchBar(e) {
    $('.nav-operating .open-search-btn').toggleClass('to-close');
    $('.nav-operating .search-bar').toggleClass('active');

    if ($(e.currentTarget).hasClass('search-cancel')) {
      $(e.currentTarget).siblings('.input-controller').val('');
    }

    const { windowH, btmListH } = {
      windowH: $(window).innerHeight(),
      btmListH: $('.nav-list').outerHeight(),
    };

    this.setBorrowedBoxMobileLayout({ windowH, btmListH });
  },
  setBorrowedBoxMobileLayout({ windowH, btmListH }) {
    const { newNavH, boxPd, explainH } = {
      newNavH: $('.nav-operating').outerHeight(),
      boxPd: $('.borrowed-box').outerWidth() - $('.borrowed-box').width(),
      explainH: $('.borrowed-words').outerHeight(),
    };
    const newBoxH = windowH - btmListH - newNavH;

    $('.borrowed-box').css('height', `${newBoxH}px`);
    $('.borrowed-box').css('top', `${newNavH}px`);
    $('.borrowed-list').css('max-height', `${newBoxH - boxPd - explainH}px`);
  },
  isIE() {
    const { userAgent } = navigator;
    const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
    const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;

    return isIE || isIE11;
  },
  resetLimitLineText(len) {
    const limitLineItem = Array.from($('[class*="limit-line"]'));

    const newText = limitLineItem.map((item) => {
      const beforeText = $(item).text().trim();
      return beforeText.length > len ? `${beforeText.substring(0, len)}...` : beforeText;
    });

    limitLineItem.forEach((item, index) => {
      $(item).text(newText[index]);
    });
  },
  toggleBorrowedBox() {
    if ($('.borrow-book-btn').hasClass('to-close')) {
      $('.main-area').css('overflow-y', 'scroll');
    } else {
      $('.main-area').css('overflow-y', 'hidden');
    }

    $('.borrow-book-btn').toggleClass('to-close');
    $('.borrowed-box').toggleClass('active');
  },
  closeBorrowedBoxIfOpened(e) {
    if (
      $(e.target).hasClass('borrow-book-btn')
      || $(e.target).parents('.borrow-book-btn').length > 0
    ) {
      return;
    }
    if (
      $('.borrow-book-btn').hasClass('to-close')
      && $(e.target).parents('.borrowed-box').length < 1
    ) {
      this.toggleBorrowedBox();
    }
  },
  scrollToTop() {
    // if ($(window).innerWidth() > this.mobileWdWidth) {
    $('html').scrollTop(0);
    //   return;
    // }
    // if ($('.main-area').length < 1) {
    //   return;
    // }
    $('.main-area').scrollTop(0);
  },
  modalCallback: null,
  closeModal(e) {
    if ($(e.currentTarget).data('type') === 'loading') {
      return;
    }
    if (
      $(e.target).parents('.modal-window').length > 0
      && !$(e.target).hasClass('btn-close-modal')
    ) {
      return;
    }
    this.toggleModal({ type: 'close' });
  },
  toggleModal({ type = 'loading', content = null, onFinish }) {
    if (type === 'close') {
      $('.modal').removeClass('active');
      $('body').removeClass('lock');
      $('.modal-content').html('');
      if (this.modalCallback) {
        this.modalCallback();
        this.modalCallback = null;
      }
      return;
    }
    $('.modal').data('type', type);
    $('.modal').attr('data-type', type);
    if (type === 'window') {
      $('.modal-content').empty();
      $('.modal-content').append(content);
    }
    $('.modal').addClass('active');
    $('body').addClass('lock');
    if (onFinish) {
      if (typeof onFinish !== 'function') {
        return;
      }
      this.modalCallback = onFinish;
    }
  },
  steps: {
    1: '步驟一',
    2: '步驟二',
    3: '步驟三',
    4: '步驟四',
  },
  instData: {
    pc: {
      tw: {
        1: {
          title: '登入FunPark Library圖書館電子書借閱服務',
          img: [require('@img/pc-1-1.jpg')],
        },
        2: {
          title: '借閱/歸還電子書',
          img: [require('@img/pc-2-1.jpg'), require('@img/pc-2-2.jpg'), require('@img/pc-2-3.jpg')],
        },
        3: {
          title: '開始享受互動電子童書',
          img: [
            require('@img/pc-book-title-1.png'),
            require('@img/tw-stage.jpg'),
            require('@img/pc-3-2.jpg'),
            require('@img/pc-3-3.jpg'),
            require('@img/pc-3-4.jpg'),
            require('@img/pc-book-title-2.png'),
            require('@img/pc-3-5.jpg'),
            require('@img/pc-3-6.jpg'),
            require('@img/pc-3-7.jpg'),
          ],
        },
      },
      hk: {
        1: {
          title: '進入香港公共圖書館網頁 https://www.hkpl.gov.hk/',
          img: [require('@img/hk-pc-1.jpg')],
        },
        2: {
          title: '登入FunPark童書夢工廠',
          img: [require('@img/hk-pc-2.jpg')],
        },
        3: {
          title: '選擇喜愛的圖書即可開始閱讀',
          img: [
            require('@img/pc-hk-index.jpg'),
            require('@img/hk-pc-3-2.png'),
            require('@img/hk-2.jpg'),
            require('@img/hk-3.jpg'),
            require('@img/hk-4.jpg'),
            require('@img/hk-pc-3-3.png'),
            require('@img/hk-5.jpg'),
            require('@img/hk-6.jpg'),
            require('@img/hk-7.jpg'),
            require('@img/hk-8.jpg'),
          ],
        },
      },
    },
    mobile: {
      tw: {
        1: {
          title: '下載FunPark童書夢工廠APP',
          img: [require('@img/mb-1-1.jpg')],
        },
        2: {
          title: '登入FunPark童書夢工廠APP',
          img: [
            require('@img/mb-2-1.jpg'),
            require('@img/mb-2-2.jpg'),
            require('@img/mb-2-3.jpg'),
            require('@img/mb-2-4.jpg'),
          ],
        },
        3: {
          title: '借閱/歸還電子書',
          img: [require('@img/mb-3-1.jpg'), require('@img/mb-3-2.jpg'), require('@img/mb-3-3.jpg')],
        },
        4: {
          title: '開始享受互動電子童書',
          img: [require('@img/mb-4.jpg')],
        },
      },
      hk: {
        1: {
          title: '下載FunPark童書夢工廠APP',
          img: [require('@img/mb-1-1.png')],
        },
        2: {
          title: '登入FunPark童書夢工廠APP',
          img: [
            require('@img/mb-2-1.jpg'),
            require('@img/mb-2-2.jpg'),
            require('@img/hk-mb-2-1.png'),
            require('@img/hk-mb-2-2.png'),
            require('@img/hk-mb-2-3.png'),
          ],
        },
        3: {
          title: '借閱/歸還電子書',
          img: [require('@img/hk-mb-3.jpg')],
        },
        4: {
          title: '開始享受互動電子童書',
          img: [require('@img/hk-mb-4.jpg')],
        },
      },
    },
  },
  area: 'tw',
  instType: 'pc',
  instStep: {
    pc: 1,
    mobile: 1,
  },
  lastStep: $('.step-bar[data-type="pc"] .step-item[data-last="true"').data('step'),
  setArea(area) {
    this.area = area;
  },
  setStepBar(type) {
    $('.step-bar').removeClass('active');
    $(`.step-bar[data-type="${type}"]`).addClass('active');
    this.lastStep = $(`.step-bar[data-type="${type}"] .step-item[data-last="true"`).data('step');
  },
  switchInstType(e) {
    const type = $(e.currentTarget).data('type');
    this.instType = type;
    $('.btn-switch-type').parent('.tab').removeClass('active');
    $(e.currentTarget).parent('.tab').addClass('active');
    $('.inst-intro-img-box').toggleClass('sm-img', type === 'mobile');
    this.colorStepItem(this.instStep[type]);
    this.setStepBar(this.instType);
    this.replaceInstTextData(this.instStep[type]);
    this.replaceInstImgData(this.instStep[type]);
  },
  switchInstStep(e) {
    const step = $(e.currentTarget).data('step');
    this.instStep[this.instType] = step;
    this.colorStepItem(step);
    this.replaceInstTextData(step);
    this.replaceInstImgData(step);
  },
  goNextInstStep() {
    const curInstStep = this.instStep[this.instType];
    this.instStep[this.instType] = curInstStep + 1 > this.lastStep ? 1 : curInstStep + 1;
    this.colorStepItem(this.instStep[this.instType]);
    this.replaceInstTextData(this.instStep[this.instType]);
    this.replaceInstImgData(this.instStep[this.instType]);
  },
  colorStepItem(step) {
    $('.step-item').removeClass('active');
    $(`.step-item[data-step="${step}"]`).addClass('active');
    if (step === this.lastStep) {
      $(`.step-item[data-step="${this.lastStep}"]`).addClass('active');
    }
  },
  replaceInstTextData(step) {
    $('.inst-intro-num').text(step);
    $('.inst-intro-step-title').text(this.steps[step]);
    $('.inst-intro-title').text(this.instData[this.instType][this.area][step].title);
  },
  replaceInstImgData(step) {
    return new Promise((resolve) => {
      $('.inst-intro-img-box').html('');
      this.instData[this.instType][this.area][step].img.forEach((img, index) => {
        const newImg = document.createElement('img');
        newImg.alt = 'FunPark Library圖書館電子書借閱服務使用說明';
        newImg.classList.add('inst-intro-img');
        newImg.src = img;
        $('.inst-intro-img-box').append(newImg);
        if (index === this.instData[this.instType][this.area][step].img.length - 1) {
          resolve();
        }
      });
    });
  },
  animate({ timing, draw, duration }) {
    const start = performance.now();

    requestAnimationFrame(function animate(time) {
      let timeFraction = (time - start) / duration;
      if (timeFraction > 1) timeFraction = 1;

      const progress = timing(timeFraction);

      draw(progress);

      if (timeFraction < 1) {
        requestAnimationFrame(animate);
      }
    });
  },
  animateTextTimer: null,
  animateText(textArea) {
    const targetNode = textArea;
    const text = textArea.textContent.trim();
    const to = text.length;
    const from = 0;
    const duration = 1500;
    const animate = () => {
      this.animate({
        duration,
        timing(timeFraction) {
          return timeFraction;
        },
        draw: (progress) => {
          const result = (to - from) * progress + from;
          targetNode.innerHTML = text.substr(0, Math.ceil(result));
        },
      });
    };

    animate();
    this.animateTextTimer = setInterval(() => {
      animate();
    }, duration);
  },
  observeAnimate(val) {
    if ($(val[0].target).data('type') !== 'loading') {
      if (this.animateTextTimer) clearInterval(this.animateTextTimer);
      return;
    }
    if ($(val[0].target).hasClass('active')) {
      this.animateText(val[0].target.querySelector('.loading-words-en'));
    } else {
      clearInterval(this.animateTextTimer);
    }
  },
  openContentModal({
 type = 'iframe', src = '', width = 600, height = 400, onFinish,
}) {
    if (!src || (type !== 'iframe' && type !== 'img')) {
      return;
    }

    const el = document.createElement(type);
    el.src = src;

    if (type === 'iframe') {
      el.width = width;
      el.height = height;
    }

    this.toggleModal({
      type: 'window',
      content: el,
      onFinish,
    });
  },
  toggleHeader() {
    let lastScrollTop = 0;

    $(window).on('resize', () => {
      lastScrollTop = 0;
    });

    window.addEventListener(
      'touchmove',
      (e) => {
        e.preventDefault();
      },
      false,
    );

    document.querySelector('.main-area').addEventListener(
      'touchmove',
      (e) => {
        e.stopPropagation();
      },
      false,
    );

    document.querySelector('.main-area').addEventListener('scroll', (e) => {
      const st = e.target.scrollTop;
      if (Math.abs(st - lastScrollTop) < 100) {
        return;
      }
      if (st > lastScrollTop) {
        // down
        $('.nav').addClass('hide');
      } else {
        // up
        $('.nav').removeClass('hide');
      }
      lastScrollTop = st <= 0 ? 0 : st;
    });
  },
};
